import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.jpeg";
import E_verify_Logo from "../../assets/e_verify_logo.png";
import { useNavigate } from "react-router";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="section-one">
          <img src={logo} alt="logo" className={"logo"} />
          <p className={"info_main"}>
            HARA IT helps businesses to achieve in depth understanding of
            customer needs and requirements, provide them an innovative product.
          </p>
          <div className={"socials"}>
            <a
              href="https://www.facebook.com/profile.php?id=100092378850510"
              target="_blank"
            >
              <AiFillFacebook className="social_btn" size={22} color="#fff" />
            </a>
            <a href="http://instagram.com/hara_it_solutions/" target="_blank">
              <AiOutlineInstagram
                className="social_btn"
                size={22}
                color="#fff"
              />
            </a>
            <a href="https://twitter.com/solutions_hara" target="_blank">
              <AiFillTwitterCircle
                hr
                className="social_btn"
                size={22}
                color="#fff"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/hara-it-solutions-llc/"
              target="_blank"
            >
              <AiFillLinkedin className="social_btn" size={22} color="#fff" />
            </a>
          </div>
        </div>
        <div className={"categories"}>
          <h2 className={"h2"}>Quick Links</h2>
          <ul className={"ul"}>
            <li className={"li"} onClick={() => navigate("/")}>
              Home
            </li>
            <li className={"li"} onClick={() => navigate("/about")}>
              About Us
            </li>
            <li className={"li"} onClick={() => navigate("/services")}>
              Services
            </li>
            <li className={"li"} onClick={() => navigate("/careers")}>
              Careers
            </li>
            <li className={"li"} onClick={() => navigate("/contact")}>
              Contact Us
            </li>
          </ul>
        </div>
        <div className={"search-container"}>
          <h2 className={"h2"}>Get In Touch</h2>
          <p className="info">
            Head Office: 317 George St Suite # 320 New Brunswick, NJ 08901
          </p>
          <p className={"info"}>+1 (848) 437-0444</p>
          <p className={"info"}>info@haraitsolutions.com</p>
          <img className="e_verify_logo" src={E_verify_Logo} />
        </div>
      </div>
      <p className={"copyright"}>
        Copyright ©️{" "}
        <a>
          <span> Hara IT Solutions </span>
        </a>
        {" 2023 Company. All right reserved."}
      </p>
    </>
  );
};

export default Footer;
