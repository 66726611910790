import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.jpeg";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";

function Header() {
  const pathname = useLocation().pathname;
  const [mobileNav, setMobileNav] = useState(false);

  return (
    <header className="header_main">
      <Link to="/">
        <img src={Logo} />
      </Link>
      <div className="main_nav">
        <Link
          className={pathname === "/" ? "nav_item_active" : "nav_item"}
          to="/"
        >
          HOME
        </Link>
        <Link
          className={pathname === "/about" ? "nav_item_active" : "nav_item"}
          to="/about"
        >
          ABOUT US
        </Link>
        <Link
          className={pathname === "/services" ? "nav_item_active" : "nav_item"}
          to="/services"
        >
          SERVICES
        </Link>
        <Link
          className={pathname === "/careers" ? "nav_item_active" : "nav_item"}
          to="/careers"
        >
          CAREERS
        </Link>
        <Link
          className={pathname === "/contact" ? "nav_item_active" : "nav_item"}
          to="/contact"
        >
          CONTACT US
        </Link>
      </div>
      <div className="mobile_nav">
        <HiOutlineMenuAlt3
          onClick={() => {
            setMobileNav(!mobileNav);
          }}
          size={35}
        />
      </div>
      <div
        className={
          mobileNav === true ? "mobile_nav_div_active" : "mobile_nav_div"
        }
      >
        <IoIosCloseCircle
          onClick={() => setMobileNav(!mobileNav)}
          size={40}
          color="#000d57"
          style={{ marginBottom: "2rem", alignSelf: "flex-start" }}
        />
        <Link
          onClick={() => setMobileNav(!mobileNav)}
          className={pathname === "/" ? "nav_item_active" : "nav_item"}
          to="/"
        >
          HOME
        </Link>
        <Link
          onClick={() => setMobileNav(!mobileNav)}
          className={pathname === "/about" ? "nav_item_active" : "nav_item"}
          to="/about"
        >
          ABOUT US
        </Link>
        <Link
          onClick={() => setMobileNav(!mobileNav)}
          className={pathname === "/services" ? "nav_item_active" : "nav_item"}
          to="/services"
        >
          SERVICES
        </Link>
        <Link
          onClick={() => setMobileNav(!mobileNav)}
          className={pathname === "/careers" ? "nav_item_active" : "nav_item"}
          to="/careers"
        >
          CAREERS
        </Link>
        <Link
          onClick={() => setMobileNav(!mobileNav)}
          className={pathname === "/contact" ? "nav_item_active" : "nav_item"}
          to="/contact"
        >
          CONTACT US
        </Link>
      </div>
    </header>
  );
}

export default Header;
