import React, { useState } from "react";
import "./ServicesComp.css";
import ImgBack from "../../assets/home.jpg";
import { BiCodeAlt } from "react-icons/bi";
import { MdDesignServices, MdOutlineAssuredWorkload } from "react-icons/md";
import { HiDeviceMobile } from "react-icons/hi";
import { Link } from "react-router-dom";
import { DiPython } from "react-icons/di";

function ServicesComp() {
  const [services, setServices] = useState([
    {
      icon: HiDeviceMobile,
      name: "Mainframes",
      description:
        "Mainframe OS installation, (JCl) Job control language program execution, Dataset allocation: ISPF/PDF, COBOL DB2 program coding, Processing VSAM datasets, Application development using CICS.",
    },
    {
      icon: BiCodeAlt,
      name: "Java/J2EE",
      description:
        "Basic Understanding of the concepts of MVC (Model-View-Controller) Pattern, JDBC (Java Database Connectivity), and RESTful web services",
    },
    {
      icon: MdDesignServices,
      name: ".Net Developer",
      description: `· Build web-based UIs and HTTP based APIs from high-level wireframes and design documents
        · Collaborate with developers and stakeholders to define and build high-quality software
        · Continually improve our technical stack and processes
        · Source control - Branch management, code merging, and maintenance
        · Design, implement and manage DevOps for automated builds and deployments
        · Maintain existing tools and infrastructure as necessary`,
    },
    {
      icon: MdOutlineAssuredWorkload,
      name: "Quality Assurance Engineer",
      description:
        "Seeking qualified Quality Assurance Engineer with Bachelor's Degree or foreign equivalent in CS or CIS along with 60 months of related work experience to Analyzing the Business Requirement and Functional Requirement Documents to Understand and Create Test Plan and Test Strategy, Performing Regression, Functional and Stress Testing as per the Deployed Build. Partnering Across Business and Development Teams to analyze and provide resolution for Defects. Work on the CI/CD Tools to integrate Various Applications to minimize the Manual Efforts, Creating Framework in Java and .Net for Automation using Selenium, Appium, LitmusT and SoapUI, Database tools like Microsoft IBM DB2, TDM and RTDMS to pull Data as per Data Requirements, Environment Readiness. Prepare Project Support Documents like Requirement Traceability Matrix, Test Execution and Defect Reports. Perform Sanity Test, System Test and Smoke Test after each Deployment, Post production Activities on Application Under Test. Travel may be required to unanticipated worksite location.",
    },
    {
      icon: DiPython,
      name: "Python / PHP Developer",
      description: `Hands on development Design and Develop applications using Databricks
        Should be strong and have good experience on Python Pyspark
        Hands on experience on Data Warehousing and SQL
        Experience on Pandas
        Solid experience with git software version control and good
        understanding of code branching strategies and organization for code reuse
        Preferred to have Data Bricks knowledge
        Experience with DevOps & CI/CD tools – Jenkins, Cloudbees, Please Build, etc.
        Proficiency working with large data stores and data sets`,
    },
  ]);
  return (
    <div className="services_content">
      <div className="content_head">
        <h1>SERVICES</h1>
        <hr />
      </div>
      <div className="service_items_list">
        {services.map((service) => {
          return (
            <div>
              <service.icon className="service_icon" />
              <h2>{service.name}</h2>
              <p>{service.description}</p>
              <Link to={`/services#${service.name}`}>
                <button>Learn More</button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ServicesComp;
