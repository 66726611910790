import React, { useEffect } from "react";
import "./About.css";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about_main">
      <h1>About Us</h1>
      <p>
        Hara It Solutions LLC is a vibrant and ambitious team of skilled
        professionals who are well-versed in providing the IT Solutions. Our IT
        expert’s team works as an extension for your in-house IT team to
        understand your enterprise needs and requirements to provide the best
        solutions. As a number one professional services provider, the needs of
        our clients are our number one priority. We take the burden of by
        concentrating on client business needs. Hence, customer can have more
        time to dedicate towards their needs.
      </p>
      <p>
        Our software consultants are the backbone of our organization. We hire
        highly skilled and experienced software professionals to accomplish our
        mission of providing the best software consulting services. We take
        great care in choosing the right candidates for our software consultant
        positions to satisfy the needs of our clients.
      </p>
      <h1>Vision and Mission:</h1>
      <p>
        Thousands of customers rely on our comprehensive resources and network
        of hidden talent to adapt to changing business demands. In addition, our
        staffing consultants can help you provide individuals and groups of
        competent experts to work securely onsite or remotely. We work together
        to attain a shared objective of delivering high-quality work in 24
        hours.
      </p>
      <h1>Our Mission:</h1>
      <p>
        To be globally recognized for our quality and providing consultancy
        services along with solutions to our customers.
      </p>
      <h1>Our Vision:</h1>
      <p>
        Every action we do aims to create change. Our strong collaboration and
        adaptability have positioned us as a leader in the IT & non-IT
        consulting and staffing services industry because of our strong
        coordination and flexibility in our approach. Our main mission is to
        help our clients succeed by delivering cutting-edge solutions that
        minimize project risks, improve capabilities, and add value while
        staying within budget and deadline constraints. Our Mission & Vision set
        a direction for our business and a "Roadmap" for winning with our valued
        partners.
      </p>
      <h1>OUR COMMITMENT:</h1>
      <p>
        Our commitment towards our employees was phenomenal, We committed to
        meet and surpass our client expectations. We commit to the highest
        standards of ethics, professionalism and transparency in our business.
        We commit to complete ownership and accountability, thereby earning the
        trust and respect of our employees and clients.
      </p>
    </div>
  );
}

export default About;
