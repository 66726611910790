import React, { useEffect } from "react";
import ServicesComp from "../../components/ServicesComp/ServicesComp";
import Clients from "../../components/Clients/Clients";
import ImgBack from "../../assets/home.jpg";
import "./Home.css";
import Carousel from "../../components/Carousel/Carousel";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home_main">
      <img src={ImgBack} />
      <Carousel />
      <ServicesComp />
      <Clients />
    </div>
  );
}

export default Home;
