import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import PreHeader from "./components/PreHeader/PreHeader";
import Home from "./pages/Home/Home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Careers from "./pages/Careers/Careers";
import Contact from "./pages/Contact/Contact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <PreHeader />
        <Header />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<Services />} path="/services" />
          <Route element={<Careers />} path="/careers" />
          <Route element={<Contact />} path="/contact" />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
