import React, { useEffect } from "react";
import "./Careers.css";
import Contact from "../Contact/Contact";

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const careers = [
    {
      name: "Software Developer",
      description: (
        <p>
          1. Developing and directing software system validation and testing
          methods.
          <br />
          2. Directing our software programming initiatives
          <br />
          3. Overseeing the development of documentation.
          <br />
          4. Working closely with clients and cross-functional departments to
          communicate project statuses and proposals.
          <br />
          5. Analyzing data to effectively coordinate the installation of new
          systems or the modification of existing systems.
          <br /> 6. Managing the software development lifecycle. <br /> 7.
          Monitoring system performance. <br />
          8. Communicating key project data to team members and building
          cohesion among teams.
          <br />
          9. Developing and executing project plans.
          <br /> 10. Applying mathematics and statistics to problem-solving
          initiatives.
          <br /> 11. Applying best practices and standard operating procedures.
          <br />
          12. Creating innovative solutions to meet our company’s technical
          needs.
          <br />
          13. Testing new software and fixing bugs.
          <br />
          14. Shaping the future of our systems <br />
          Requirements : <br />
          This is a professional position, and as such, we require, at minimum,
          a Bachelor’s degree in computer science or related field.
        </p>
      ),
    },
    {
      name: "QA Analyst",
      description: (
        <p>
          1. Developing and directing software system validation and testing
          methods.
          <br />
          2. Directing our software programming initiatives
          <br />
          3. Overseeing the development of documentation.
          <br />
          4. Working closely with clients and cross-functional departments to
          communicate project statuses and proposals.
          <br />
          5. Analyzing data to effectively coordinate the installation of new
          systems or the modification of existing systems.
          <br /> 6. Managing the software development lifecycle. <br /> 7.
          Monitoring system performance. <br />
          8. Communicating key project data to team members and building
          cohesion among teams.
          <br />
          9. Developing and executing project plans.
          <br /> 10. Applying mathematics and statistics to problem-solving
          initiatives.
          <br /> 11. Applying best practices and standard operating procedures.
          <br />
          12. Creating innovative solutions to meet our company’s technical
          needs.
          <br />
          13. Testing new software and fixing bugs.
          <br />
          14. Shaping the future of our systems <br />
          Requirements : <br />
          This is a professional position, and as such, we require, at minimum,
          a Bachelor’s degree in computer science or related field.
        </p>
      ),
    },
    {
      name: "API Developer",
      description: (
        <p>
          1. Developing and directing software system validation and testing
          methods.
          <br />
          2. Directing our software programming initiatives
          <br />
          3. Overseeing the development of documentation.
          <br />
          4. Working closely with clients and cross-functional departments to
          communicate project statuses and proposals.
          <br />
          5. Analyzing data to effectively coordinate the installation of new
          systems or the modification of existing systems.
          <br /> 6. Managing the software development lifecycle. <br /> 7.
          Monitoring system performance. <br />
          8. Communicating key project data to team members and building
          cohesion among teams.
          <br />
          9. Developing and executing project plans.
          <br /> 10. Applying mathematics and statistics to problem-solving
          initiatives.
          <br /> 11. Applying best practices and standard operating procedures.
          <br />
          12. Creating innovative solutions to meet our company’s technical
          needs.
          <br />
          13. Testing new software and fixing bugs.
          <br />
          14. Shaping the future of our systems <br />
          Requirements : <br />
          This is a professional position, and as such, we require, at minimum,
          a Bachelor’s degree in computer science or related field.
        </p>
      ),
    },
    {
      name: "Network Engineer",
      description: (
        <p>
          1. Developing and directing software system validation and testing
          methods.
          <br />
          2. Directing our software programming initiatives
          <br />
          3. Overseeing the development of documentation.
          <br />
          4. Working closely with clients and cross-functional departments to
          communicate project statuses and proposals.
          <br />
          5. Analyzing data to effectively coordinate the installation of new
          systems or the modification of existing systems.
          <br /> 6. Managing the software development lifecycle. <br /> 7.
          Monitoring system performance. <br />
          8. Communicating key project data to team members and building
          cohesion among teams.
          <br />
          9. Developing and executing project plans.
          <br /> 10. Applying mathematics and statistics to problem-solving
          initiatives.
          <br /> 11. Applying best practices and standard operating procedures.
          <br />
          12. Creating innovative solutions to meet our company’s technical
          needs.
          <br />
          13. Testing new software and fixing bugs.
          <br />
          14. Shaping the future of our systems <br />
          Requirements : <br />
          This is a professional position, and as such, we require, at minimum,
          a Bachelor’s degree in computer science or related field.
        </p>
      ),
    },
    {
      name: "Java Developer",
      description: (
        <p>
          1. Developing and directing software system validation and testing
          methods.
          <br />
          2. Directing our software programming initiatives
          <br />
          3. Overseeing the development of documentation.
          <br />
          4. Working closely with clients and cross-functional departments to
          communicate project statuses and proposals.
          <br />
          5. Analyzing data to effectively coordinate the installation of new
          systems or the modification of existing systems.
          <br /> 6. Managing the software development lifecycle. <br /> 7.
          Monitoring system performance. <br />
          8. Communicating key project data to team members and building
          cohesion among teams.
          <br />
          9. Developing and executing project plans.
          <br /> 10. Applying mathematics and statistics to problem-solving
          initiatives.
          <br /> 11. Applying best practices and standard operating procedures.
          <br />
          12. Creating innovative solutions to meet our company’s technical
          needs.
          <br />
          13. Testing new software and fixing bugs.
          <br />
          14. Shaping the future of our systems <br />
          Requirements : <br />
          This is a professional position, and as such, we require, at minimum,
          a Bachelor’s degree in computer science or related field.
        </p>
      ),
    },
  ];

  return (
    <div className="careers_main">
      <div className="careers_head">
        <h1>Careers at HARA IT Solutions</h1>
        <hr />
      </div>
      {careers.map((item, index) => {
        return (
          <details key={index} className="career_item">
            <summary>{item.name}</summary>
            {item.description}
          </details>
        );
      })}
      <Contact title="Apply for this jobs" />
    </div>
  );
}

export default Careers;
