import React from "react";
import Client1 from "../../assets/image1.png";
import Client2 from "../../assets/image2.png";
import Client3 from "../../assets/image3.png";
import Client4 from "../../assets/image4.png";
import Client5 from "../../assets/image5.png";
import Client6 from "../../assets/image6.png";
import "./Clients.css";

function Clients() {
  return (
    <div className="clients_content">
      <div className="content_head">
        <h1>CLIENTS</h1>
        <hr />
      </div>
      <p>
        Hara IT Solutions is a leading information technology (IT) Services
        Company that specializes in providing complete consulting solutions and
        staff augmentation to its clients. We provide on-time, on-budget, and
        quality service to our clients and consistently meet their expectations.
        Our mission is to be the number one choice of companies seeking
        technology solutions, and to deliver, reinforce, and maintain our
        passionate commitment to our clients’ success.
      </p>
      <br />
      <p>
        Hara IT Solutions is dedicated to providing you quality professionals
        with well-matched skills to your unique hiring requirements and
        workplace environment. We collaborate with customers to consistently and
        cost-effectively plan, execute and deliver high-quality services and
        results.
      </p>
      {/* <div className="clients_div">
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client1} />
        </a>
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client2} />
        </a>
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client3} />
        </a>
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client4} />
        </a>
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client5} />
        </a>
        <a href="https://haraitsolutions.com/" target="_blank">
          <img src={Client6} />
        </a>
      </div> */}
    </div>
  );
}

export default Clients;
