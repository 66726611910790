import React from "react";
import "./Carousel.css";

function Carousel() {
  return (
    <div className="carousel_home">
      <div>
        <h1>{"HARA IT HELPS\nBUSINESSES"}</h1>
        <p className="legend">
          HARA IT helps businesses to achieve in depth understanding of customer
          needs and requirements, provide them an innovative product.
        </p>
      </div>
      {/* <div>
        <h1>HARA IT HELPS BUSINESSES</h1>
        <p className="legend">
          {
            "HARA IT helps businesses to achieve in depth understanding\nof customer needs and requirements, provide them with innovative..."
          }
        </p>
      </div> */}
    </div>
  );
}

export default Carousel;
